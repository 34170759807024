import https from '../service'

const getQunCoinInfoV2 = (params: any) => {
  return https.post({
    url: '/qsApi/BehaviorController/getQunCoinInfoV2',
    params
  })
}

const userScanCode = (params: any) => {
  return https.get({
    url: '/sApi/SecurityCodeController/userScanCode',
    params
  })
}

const verifyCode = (params: any) => {
  return https.get({
    url: '/sApi/SecurityCodeController/verifyCode',
    params
  })
}
export { getQunCoinInfoV2, userScanCode,verifyCode }
